@charset "UTF-8";
@import "../common/_settings/_setting";

/**
 * index
 */
body[id="index"] {
  .swiper-container {
    height: 100%;
  }
  .headerWrap {
    @include mq-sp {
      // height: 10%;
    }
  } //headerWrap
  &.-spMenuOpen {
    .headerWrap {
      height: 100vh;
    }
  }
  .mv {
    @include mq-pc {
      height: 100vh;
      display: flex;
    }
    @include mq-sp {
      width: 100%;
      // height: 90%;
      position: relative;
      display: block;
    }
    .mvTxt {
      @include mq-pc {
        padding-left: (65 / 1440 * 100) + vw;
        width: (218 / 1440 * 100) + vw;
        // background-color: powderblue;
        padding-top: (285 / 1440 * 100) + vw;
      }
      @media screen and (min-width: $wide-basis) {
        padding-top: 28.5rem;
      }
      @include mq-sp {
        // height: 65%;
        margin-bottom: (47 / 375 * 100) + vw;
        // padding-right: $mv_rl-sp;
      }
    } //mvTxt
    .mvImgWrap {
      @include mq-pc {
        margin-left: (73 / 1440 * 100) + vw;
        width: (1150 / 1440 * 100) + vw;
      }
      @include mq-sp {
        bottom: 0;
        // height: 35%;
        // background-color: powderblue;
      }
    } //mvImgWrap
    .-commonMvTxt {
      &Item {
        @include mq-pc {
          font-size: 1.68rem;
          margin-left: (28 / 1440 * 100) + vw;
          margin-right: unset;
        }
        @media screen and (min-width: $wide-basis) {
          margin-left: 2.8rem;
        }
      }
    }
  } //mv

  .section {
    &__about,
    &__works {
      @include mq-pc {
      }
      @include mq-sp {
        padding-right: $content_inner_rl-sp;
        padding-left: $content_inner_rl-sp;
        margin-bottom: (82 / 375 * 100) + vw;
      }
    } //__about__works
    &__about,
    &__works,
    &__recruit {
      .section__title {
        @include mq-pc {
          margin-bottom: 6.5rem;
        }
        @include mq-sp {
          margin-bottom: (26 / 375 * 100) + vw;
        }
      }
    } //__about__works__recruit
    &__about {
      @include mq-pc {
        // heade高差分プラス
        margin-top: (12.5 + 18.6) + rem;
        margin-bottom: 16.2rem;
      }
      @include mq-sp {
        // 潜り込んでいる分プラス
        margin-top: (6 + (60 / 375 * 100)) + vw;
      }
    } //__about
    &__works {
      @include mq-pc {
        margin-top: 18.6rem;
        margin-bottom: 22rem;
      }
      @include mq-sp {
        margin-top: (84 / 375 * 100) + vw;
      }
    } //__works

    &__guidance {
      background-color: clr(bgBeigeColor);
      @include mq-pc {
        padding: 12rem 0;
      }
      @include mq-sp {
        padding: (78 / 375 * 100) + vw 0;
      }
    } //__guidance
    &__contact {
      @include mq-pc {
        margin-top: ((202 - 80) / 1440 * 100) + vw;
      }
      @include mq-sp {
        margin-top: (75 / 375 * 100) + vw;
      }
    } //__contact
    &__guidance {
      .guidanceItemList {
        display: flex;
        // background-color: pink;
        @include mq-sp {
          flex-wrap: wrap;
        }
      }
      .section__title {
        @include mq-pc {
          margin-bottom: 3rem;
          display: inline-block;
          margin-top: -0.5rem;
        }
        @include mq-sp {
          width: 100%;
          margin-bottom: (15 / 375 * 100) + vw;
        }
      }
      .guidanceItem {
        @include mq-pc {
          width: (346 / 1440 * 100) + vw;
        }
        @include mq-sp {
          width: 100%;
        }
        &:not(:last-child)　 {
          @include mq-sp {
            margin-bottom: (13 / 375 * 100) + vw;
          }
        }
        &.-title {
          // background-color: red;
          @include mq-pc {
            display: flex;
            flex-direction: column;
            max-width: 19.7rem;
            width: 100%;
          }
        }
        &:nth-child(2) {
          @include mq-pc {
            margin-left: (133 / 1440 * 100) + vw;
            margin-right: (56 / 1440 * 100) + vw;
          }
        }
        .guidanceItemTxt {
          line-height: 2;
        }
      } //guidanceItem
      .readMore {
        @include mq-pc {
          margin-top: auto;
        }
        @include mq-sp {
          margin-top: (28 / 375 * 100) + vw;
        }
      }
    } //__guidance
    &__recruit {
      .section__title {
        margin-top: 0;
      }
      @include mq-pc {
        &.bgGray_relativ_pc {
          position: relative;
        }
        .bgGray_pc {
          position: absolute;
          height: 100%;
          bottom: 0;
          margin-left: 0;
          left: 0;
          background-color: clr(bgBeigeColor);
          width: (930 /1440 * 100) + vw;
          top: -(40 / 1440 * 100) + vw;
        }
      }
      .-commonLayoutImgTxtWrap {
        @include mq-pc {
          position: relative;
          z-index: 1;
          padding-bottom: ((40 / 1440 * 100) * 2) + vw;
          align-items: center;
        }
      }
      .section__txtBox {
        @include mq-pc {
          // padding: 12.7rem 0;
        }
        @include mq-sp {
          background-color: clr(bgBeigeColor);
          padding: (82 / 375 * 100) + vw $content_inner_rl-sp;
        }
      }
      .readMore {
        @include mq-pc {
          margin-top: 6.8rem;
        }
        @include mq-sp {
          margin-top: (28 / 375 * 100) + vw;
        }
      }
      .section__imgBox {
        @include mq-sp {
          // padding-right: $mv_rl-sp;
          // padding-left: $mv_rl-sp;
          margin-top: 0;
        }
      }
    } //__recruit
    &__contact {
      .contactItemList {
        display: flex;
        // background-color: pink;
        @include mq-sp {
          flex-wrap: wrap;
        }
      }
      .section__title {
        @include mq-pc {
          margin-bottom: 2.5rem;
          display: inline-block;
        }
        &.__titleLevel01 {
          @include mq-sp {
            margin-bottom: (30 / 375 * 100) + vw;
          }
        }
        &.__titleLevel02 {
          @include mq-pc {
            margin-top: -1rem;
          }
          &.-tel {
            @include mq-sp {
              margin-bottom: (15 / 375 * 100) + vw;
            }
          }
          &.-web {
            @include mq-sp {
              margin-top: (12 / 375 * 100) + vw;
            }
          }
        }
      }
      .contactItem {
        @include mq-pc {
          // width: (346 / 1440 * 100) + vw;
        }
        @include mq-sp {
          width: 100%;
        }
        &:not(:last-child)　 {
          @include mq-sp {
            // margin-bottom: (20 / 375 * 100) + vw;
          }
        }
        &.-title {
          // background-color: red;
          @include mq-pc {
            display: flex;
            flex-direction: column;
            max-width: 16rem;
            width: 100%;
          }
        }
        &:nth-child(2) {
          @include mq-pc {
            margin-left: (171 / 1440 * 100) + vw;
            margin-right: (98 / 1440 * 100) + vw;
          }
        }
        &:last-child {
          @include mq-pc {
            flex: 1;
          }
        }
      } //contactItem
      .readMore {
        @include mq-pc {
          margin-top: auto;
        }
        @include mq-sp {
          margin-top: (40 / 375 * 100) + vw;
        }
      }
      .btnWrap {
        @include mq-pc {
          margin-top: 0.75rem;
        }
        @include mq-sp {
          margin-top: (25 / 375 * 100) + vw;
        }
      } //btnWrap
    } //__contact
  } //section
} //index
